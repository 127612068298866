@import "main";

body {
    color: #000;
    background-color: $bodyColor;
}
// page blocks
#pageMainWrapper {
    margin: 20px 0 40px;
    background-color: $white;
    box-shadow: 20px 20px 60px lightgrey;
}
#pageHeaderWrapper {
    height: 404px;
    overflow: hidden;
}
.header-big {
    @include headerBg('big');
}
.header-small {
    height: 183px !important;
    min-height: 183px;
    @include headerBg('small');
}
#pageIntroWrapper {
    min-height: 260px;
    background-color: $green2;
}
#pageArticleWrapper {
    margin-top: 25px;
    margin-bottom: 25px;
}
#pageFooterWrapper {
    padding: 60px 0;
    color: $white;
    @include bgImg('../images/landing/footer.jpg', top content, cover, false, no-repeat);
}
#pageHeaderWrapper,
#pageIntroWrapper,
#pageArticleWrapper,
#pageTestimonialsWrapper,
#pageFooterWrapper {
    position: relative;
}
// pageHeader
#pageHeaderWrapper {

    .navbar {
        margin-top: 20px;
        height: auto !important;
        min-height: 70px !important;

        .navbar-right {
            margin-right: 5px;
            
            nav {
                line-height: 20px;
                padding: 15px;

                a {
                    text-decoration: none;
                    &:focus, &:hover {
                        text-decoration: none;
                    }
                    span {
                        i:nth-child(2) {
                            color: $green2;
                        }
                    }
                }
            }
        }
        .navbar-header {

            img.introsign-logo {
                height: 40px;
            }
        }
        .navbar-sub-header {
            padding: 0 15px 15px 15px;
            color: $white;

            h3 {
                letter-spacing: 1px;
            }
        }
    }
    .modal-content {
        @include borderRadius(12px);

        .modal-body {
            border: 1px solid $green2;
            @include borderRadius(15px);
            margin: 10px;
            padding: 30px;
        }
    }
}
// pageIntro
#pageIntroWrapper {

    hgroup.intro-quote {
        margin-top: 90px;
        color: #FFFFFF;
    }
    img.introsign-logo-big {
        height: 250px;
        position: relative;
        top: -50px;
    }

}
.intro-description {
    margin-top: 40px;

    img.intro-yellow {
        position: absolute;
        height: 480px;
        right: 0;
        margin-right: -65px;
    }
    .hr-tiny {
        width: 30px;
        margin: 60px auto 100px;
        border-bottom: 2px solid #000;
    }
}
// pageArticle
#pageArticleWrapper {

    section {
        padding: 25px 15px;

        .panel-body {
            padding: 25px 30px;
        }
    }
}
// pageArticleSections
section.boxes {
    padding: 0px !important;

    .square-box {
        width: $pinkSquareDefault320Width + ($pinkSquareDefault320Width * .2);
        height: $pinkSquareDefault320Width + ($pinkSquareDefault320Width * .2);
        padding: 25px 35px;
        color: #FFF;
        overflow: hidden;

        h2 {
            margin-bottom: 25px;

            a {
                text-decoration: none;
                color: $white;
            }
        }
    }
    .square-box.pinky {
        @include bgImg('../images/landing/pink-square-3.jpg', center, cover, false, no-repeat, $pink);
    }
    .square-box.pinky.first {
        @include bgImg('../images/landing/pink-square-1.jpg', center, cover, false, no-repeat, $pink);
    }
    .square-box.pinky.second {
        @include bgImg('../images/landing/pink-square-2.jpg', center, cover, false, no-repeat, $pink);
    }
    .square-box.pinky.pull-right,
    .square-box.empty.pull-right {
        margin-right: -15px;
    }
    .square-box.pinky.pull-left,
    .square-box.empty.pull-left {
        margin-left: -15px;
    }
    .online-payments {
        float: left;
        margin-top: -70px;
        padding-left: 15px;

        img {
            display: block;
        }
    }
    a {
        text-decoration: none;
        color: $white;

        &:hover {
            text-decoration: underline;
        }
    }
}
// pageTestimonials
#pageTestimonialsWrapper {
    padding: 60px 0 20px;

    img.img-testimony {
        width: 248px;
        height: 297px;
        margin-top: 117.5px;
    }
    .testify-box {
        width: 268px;
        height: 532px;
        padding: 70px 40px 50px;
        color: $white;
        background-color: $green2;
        position: relative;
        z-index: 2;

        p {
            line-height: normal;
        }
        .testifier-desc {
            margin-top: 60px;
        }
    }
    .testify-footer {
        height: 212px;
        position: relative;
        margin-top: -80px;
        z-index: 1;
        @include bgImg('../images/landing/testimonials.png', top left, cover, false, no-repeat);
    }
}
#contact {
    .btn.btn-success {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .fa.fa-map-marker {
        position: relative;
        margin-left: -10px;
    }
}
// pageFooter
#pageFooterWrapper {

    img.introsign-logo-footer {
        width: $logoFooter;
    }
    .get-in-touch {
        margin-right: 10px;
    }
}
// pageSignin or pageLogin
.form-signin {
    margin-top: 50px;

    .page-header {
        margin-top: 10px;
    }
}
// Miscellaneous
img.logo-pink-small {
    margin-left: 5px;
}
// Media queries
@media (min-width: 768px) {
    .modal-md {
        width: 450px;
    }
}
@media (min-width: 320px) and (max-width: 1024px) {
    .website-introsign {
        
        .container {
            padding-left: 0px;
            padding-right: 0px;
            width: auto;

            .col-md-12,
            .col-sm-12,
            .col-xs-12,
            .col-md-10 {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
    .intro-description {

        img.intro-yellow {
            margin-right: -64px;
        }
    }
    #pageMainWrapper {
        margin: 0px;
        box-shadow: none;
        #pageHeaderWrapper, #pageIntroWrapper, #pageArticleWrapper, #pageTestimonialsWrapper, #pageFooterWrapper {

            .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
@media (max-width: 1024px) and (orientation: landscape) {

    .intro-description {

        img.intro-yellow {
            margin-right: -85px;
        }
    }
    section.boxes {
        .square-box.pinky.pull-left {
            margin-left: 0px !important;
        }
        .square-box.pinky.pull-right {
            margin-right: 0px !important;
        }
    }
}
@media (max-width: 768px) and (orientation: portrait) {
    #pageIntroWrapper {

        .introsign-logo-big {
            width: 100% !important;
            height: 100%;
        }
    }
    section.boxes {
        .square-box.pinky.pull-left {
            margin-left: 0px !important;
        }
        .square-box.pinky.pull-right {
            margin-right: 0px !important;
        }
    }
    #pageTestimonialsWrapper {
        img.img-testimony {
            width: 248px - (248px * .125);
            height: 297 - (297px * .125);
        }
    }
    #pageArticleWrapper {
        section {
            .panel-body {

                a, button {
                    font-size: 12pt;
                }
            }
        }
    }
}
@media (min-width: 481px) and (max-width: 767px) {

    #pageTestimonialsWrapper {
        img.img-testimony {
            width: 248px - (248px * .3);
            height: 297 - (297px * .3);
            margin-left: -15px;
            position: relative;
            z-index: 2;
            bottom: 0;
            vertical-align: bottom;
        }
        .testify-box {
            width: 268px - (268px * .275);
            height: 532px - (532px * .388);
            padding: 40px 20px;

            p {
                &:first-child {
                    font-size: 14pt;

                    span[class*='font-27'], span[class*='font-36'] {
                        font-size: 18pt;
                    }
                }
            }
            .testifier-desc {
                margin-top: 30px;
                font-size: 12pt;
            }
        }
        .testify-footer {
            position: absolute;
            height: 212px;
            width: 100%;
            top: 140px;
        }
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .navbar {

        .navbar-right {
            nav {
                text-align: right;
            }
        }
        .navbar-sub-header {
            text-align: center;
        }
    }
    .header-big {
        .navbar-brand {
            float: none;

            img.introsign-logo {
                width: $logoDefault320Width + ($logoDefault320Width * 0.5) !important;
                height: 111px + (111px * 0.5) !important;
                margin: 0 auto;
            }
        }   
    }
    #pageHeaderWrapper {
        height: auto;
    }
    #pageIntroWrapper {
        padding-bottom: 30px;

        .col-md-5, .col-sm-5 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        hgroup.intro-quote {
            position: relative;
            margin-top: 0px;
            padding: 20px 0px 30px;
            text-align: center;
            background-color: $white;
            color: $green2;

            h3 {
                font-size: 16pt;

                p {
                    text-align: center !important;

                    br {
                        display: none;

                        &:after {
                            display: inline-block;
                            content: " ";
                        }
                    }
                }

                span {
                    font-size: 16pt !important;
                }
            }
        }
    }
    .introduction {

        h3 {
            font-size: 16pt;
        }
    }
    .intro-description {

        img.intro-yellow {
            margin-right: 0;
            height: (480px / 4) * 2.5;
        }
        h3 {
            font-size: 14pt;
        }
        h4 {
            font-size: 12pt;
        }
        .hr-tiny {
            width: 20px;
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
    section.boxes {

        .square-box.pinky.pull-right,
        .square-box.empty.pull-right,
        .square-box.pinky.pull-left,
        .square-box.empty.pull-left {
            float: none !important;
            margin: 0 auto 10px auto !important;
        }
        .square-box {
            h2 {
                font-size: 14pt;
                text-align: left !important;
            }
            p {
                font-size: 10pt;
                text-align: left !important;
            }
        }
        .online-payments {
            float: right;
            margin-top: 0;

            &:before {
                @include clearFix;
            }
            img {
                margin: 0 10px;
                width: auto!important;
                height: auto!important;
            }
            img[class*='visible-xs'] {
                display: inline-block !important;
            }
        }
    }
    .box-h {
        margin-top: 20px;
    }
}
@media (min-width: 320px) and (max-width: 480px) {

    .header-small {
        .navbar-right {
            margin-right: 0px !important;
            float: right;

            nav {
                    padding: 15px 0px !important;
            }
        }
        .navbar-sub-header {
            display: none;
        }
    }
    .btn.btn-white,
    .btn.btn-pink {
        font-size: 10pt;
    }
    section.boxes {

        .square-box.pinky.first {
            @include bgImg('../images/landing/pink-square-mobile-1.png', center, cover, false, no-repeat, $pink);
        }
        .square-box.pinky.second {
            @include bgImg('../images/landing/pink-square-mobile-2.png', center, cover, false, no-repeat, $pink);
        }
    }
}
@media (max-width: 480px) {

    .navbar {
        nav {
            a {
                font-size: 9pt;

                &:not(:last-child) {
                    padding-left: 5px;
                    padding-right: 5px;
                    margin: auto 0px;
                }
            }
        }
        .navbar-sub-header {

            h3 {
                font-size: 17pt;

                span {
                    font-size: 17pt;
                }
            }
        }
    }
    .header-big {
        .navbar-brand {
            img.introsign-logo {
                width: $logoDefault320Width + ($logoDefault320Width * .5) !important;
                height: 111px + (111px * .5) !important;
                margin: 0 auto;
            }
        }
    }
    .header-small {
        .navbar-brand {
            padding: 0px;
            margin-top: 25px;
            vertical-align: middle;

            img.introsign-logo {
                width: $logoDefault320Width - ($logoDefault320Width * .5) !important;
                height: 111px - (111px * .5) !important;
            }
        }
    }
    section.boxes {

        .square-box {
            width: $pinkSquareDefault320Width + ($pinkSquareDefault320Width * .5);
            min-height: $pinkSquareDefault320Width - ($pinkSquareDefault320Width * .5);
            height: auto;
        }
    }
    .introduction {

        h3 {
            margin-top: 10px;
            font-size: 14pt;
        }
    }
    .intro-description {
        margin-top: 20px;
        
        img.intro-yellow {
            margin-right: 0;
            height: (480px / 4) * 1.75;
        }
        h3 {
            font-size: 13pt !important;
            padding: 0 50px !important;

            &:first-child {
                font-size: 14pt !important;
            }
            span {
                font-size: 13pt !important;
            }
        }
        h4 {
            font-size: 11pt !important;
            margin-top: 15px;
            padding: 0 15px;

            span {
                font-size: 11pt !important;
            }
        }
    }
    #pageIntroWrapper {
        hgroup.intro-quote {
            h3 {
                padding-left: 80px;
                padding-right: 80px;
            }
        }
    }
    #pageTestimonialsWrapper {
        img.img-testimony {
            width: 248px - (248px * .252);
            height: 297 - (297px * .252);
            position: relative;
            z-index: 2;
            bottom: 0;
            vertical-align: bottom;
        }
        .testify-box {
            width: 268px - (268px * .065);
            height: 532px - (532px * .360);
            padding: 50px 30px;

            p {
                &:first-child {
                    font-size: 16pt;

                    span[class='font-36'] {
                        font-size: 21pt;
                    }
                }
            }
            .testifier-desc {
                margin-top: 30px;
                font-size: 14pt;
            }
        }
        .testify-footer {
            position: absolute;
            height: 212px;
            width: 100%;
            top: 140px;
        }
    }
    #pageArticleWrapper {
        section {
            .panel-body {
                padding: 15px;

                a, button {
                    font-size: 13pt;
                }
            }
        }
    }
    #contact {
        .fa.fa-envelope {
            font-size: 4em;
        }
    }
    #pageFooterWrapper {

        img.introsign-logo-footer {
            width: $logoFooter - ($logoFooter * .25);
        }
    }
}
@media (max-width: 320px) {

    .navbar {
        nav {
            a {
                font-size: 8pt;

                &:not(:last-child) {
                    padding-left: 5px;
                    padding-right: 5px;
                    margin: auto 0px;
                }
            }
        }
        .navbar-sub-header {
            margin-left: ($mobileSmWidth/8) / 4;
            margin-right: ($mobileSmWidth/8) / 4;

            h3 {
                font-size: 14pt;

                span {
                    font-size: 14pt !important;
                }
            }
        }
    }
    .header-big {
        .navbar-brand {
            img.introsign-logo {
                width: $logoDefault320Width !important;
                height: 111px !important;
                margin: 0 auto;
            }
        }
    }
    .header-small {
        .navbar-brand {
            padding: 0px;
            margin-top: 25px;
            vertical-align: middle;

            img.introsign-logo {
                width: $logoDefault320Width - ($logoDefault320Width * .6) !important;
                height: 111px - (111px * .6) !important;
            }
        }
    }
    section.boxes {

        .square-box {
            padding: 15px 25px;
            width: $pinkSquareDefault320Width;
            height: auto;

            h2 {
                margin-top: 10px;
                margin-bottom: 15px;
            }
            p {
                font-size: 9pt;
            }
        }
        .online-payments {
            text-align: center;

            img {
                margin: 0px 5px;
                width: 28px !important;
            }
        }
    }
    .introduction {

        h3 {
            margin-top: 10px;
            font-size: 14pt;
        }
    }
    .intro-description {
        margin-top: 20px;
        
        img.intro-yellow {
            margin-right: 0;
            height: (480px / 4) * 1.65;
        }
        h3 {
            font-size: 12pt !important;
            padding: 0px !important;

            span {
                font-size: 12pt !important;
            }
        }
        h4 {
            font-size: 10pt !important;
            margin-top: 15px;
            padding: 0 10px;

            span {
                font-size: 10pt !important;
            }
        }
    }
    #pageIntroWrapper {
        hgroup.intro-quote {
            h3 {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    #pageTestimonialsWrapper {
        img.img-testimony {
            width: 248px - (248px * .5);
            height: 297 - (297px * .5);
            position: relative;
            z-index: 2;
            bottom: 0;
            vertical-align: bottom;
        }
        .testify-box {
            width: 268px - (268px * .45);
            height: 532px - (532px * .5);
            margin-left: 10px;
            padding: 20px 15px;

            p {
                &:first-child {
                    font-size: 10pt !important;

                    span[class*='font-27'] {
                        font-size: 14pt !important;
                    }
                }
            }
            .testifier-desc {
                margin-top: 30px;
                font-size: 9pt;
            }
        }
        .testify-footer {
            position: absolute;
            height: 212px;
            width: 100%;
            top: 140px;
        }
    }
    #contact {

        h3 {
            font-size: 14pt;
        }
    }
    #pageArticleWrapper {
        section {
            .panel-body {
                padding: 10px;

                a, button {
                    font-size: 9pt;
                }
            }
        }
    }
    #contact {
        .fa.fa-envelope {
            vertical-align: top;
        }
    }
    #pageFooterWrapper {

        img.introsign-logo-footer {
            width: $logoFooter - ($logoFooter * .5);
            display: inline-block;
        }
        p {
            &:last-child {
                display: block;
                margin-top: 20px;
                float: right;
            }
        }
    }
}