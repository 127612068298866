
// Fonts
@include fontFace('GothamNarrowThin', '../fonts/gothamnarrow-thin', 400, normal, otf);
@include fontFace('GothamNarrowXLight', '../fonts/gothamnarrow-xlight', 400, normal, otf);
@include fontFace('GothamNarrowLight', '../fonts/gothamnarrow-light', 400, normal, otf);
@include fontFace('GothamNarrowMedium', '../fonts/gothamnarrow-medium', 400, normal, otf);
@include fontFace('GothamNarrowBook', '../fonts/gothamnarrow-book', 400, normal, otf);
@include fontFace('GothamNarrowBold', '../fonts/gothamnarrow-bold', 700, normal, otf);
@include fontFace('GothamNarrowBlack', '../fonts/gothamnarrow-black', 700, normal, otf);
@include fontFace('GothamBlack', '../fonts/gotham-black', 700, normal, otf);
@include fontFace('GaramondItalic', '../fonts/garamond-italic', 400, normal, ttf);
@include fontFace('GaramondKursivHalbfett', '../fonts/garamond-kursiv-halbfett', 400, normal, ttf);
@include fontFace('PlayfairDisplayItalic', '../fonts/playfair-display-italic', 400, normal, ttf);
@include fontFace('PlayfairDisplayRegular', '../fonts/playfair-display-regular', 400, normal, ttf);
@include fontFace('PlayfairDisplayBoldItalic', '../fonts/playfair-display-bold-italic', 700, normal, ttf);
@include fontFace('PlayfairDisplayBold', '../fonts/playfair-display-bold', 700, normal, ttf);

.gotham-nt {
    font-family: GothamNarrowThin, sans-serif;
    font-weight: 400;
}
.gotham-nxl {
    font-family: GothamNarrowXLight, sans-serif;
    font-weight: 400;
}
.gotham-nl {
    font-family: GothamNarrowLight, sans-serif;
    font-weight: 400;
}
.gotham-nm {
    font-family: GothamNarrowMedium, sans-serif;
    font-weight: 400;
}
.gotham-nbk {
    font-family: GothamNarrowBook, sans-serif;
    font-weight: 400;
}
.gotham-nb {
    font-family: GothamNarrowBold, sans-serif;
    font-weight: 700;
}
.gotham-nbl {
    font-family: GothamNarrowBlack, sans-serif;
    font-weight: 700;
}
.gotham-bl {
    font-family: GothamBlack, sans-serif;
    font-weight: 700;
}
.garamond-k {
    font-family: GaramondItalic, serif;
    font-weight: 400;
}
.garamond-kh {
    font-family: GaramondKursivHalbfett, serif;
    font-weight: 700;
    font-style: italic;
}
.playfair-i {
    font-family: PlayfairDisplayItalic, serif;
    font-weight: 400;
}
.playfair-reg {
    font-family: PlayfairDisplayRegular, serif;
    font-weight: 400;
}
.playfair-bi {
    font-family: PlayfairDisplayBoldItalic, serif;
    font-weight: 700;
}
.playfair-b {
    font-family: PlayfairDisplayBold, serif;
    font-weight: 700;
}
.font-12 {
    @include fontSize(12);
}
.font-14 {
    @include fontSize(14);
}
.font-15 {
	@include fontSize(15);
}
.font-16 {
    @include fontSize(16);
}
.font-17 {
    @include fontSize(17);
}
.font-18 {
    @include fontSize(18);
}
.font-21 {
    @include fontSize(21);
}
.font-27 {
    @include fontSize(27);
}
.font-36 {
    @include fontSize(36);
}
.font-green {
	color: $green2;
}