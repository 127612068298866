// main.scss
@import "variables";
@import "mixins";
@import "fonts";

// General purpose
.box-h {
    margin: 0px;
}
.sub-header {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
}
.color-green {
    color: $green2;
}
.text-pink {
    color: $pink!important;
}
.text-weight-300 {
    font-weight: 300;
}
.text-white {
    color: $white;
}
.contact-form-link-modal {
    margin-left: 15px;
    outline: none;
}
.intro-half-o-right {
    margin-left: 7px;
}
img.pink-square-thumb {
    width: 72px;
    margin: 0 auto;
}
.white-space-xxl,
.white-space-xl,
.white-space-l,
.white-space-md,
.white-space-sm,
.white-space-xs,
.white-space-xxs {
    @include clearFix;
}
.white-space-xxl {
    height: $whiteSpaceH;
}
.white-space-xl {
    height: $whiteSpaceH - ($whiteSpaceH/5);
}
.white-space-l {
    height: $whiteSpaceH - ($whiteSpaceH/5) * 2;
}
.white-space-md {
    height: $whiteSpaceH - ($whiteSpaceH/5) * 3;
}
.white-space-sm {
    height: $whiteSpaceH - ($whiteSpaceH/5) * 4;
}
.white-space-xs {
    height: ($whiteSpaceH - ($whiteSpaceH/5) * 4) - 10px;
}
.white-space-xxs {
    height: ($whiteSpaceH - ($whiteSpaceH/5) * 4) - 20px;
}
.pad-l5 {
    padding-left: 5px;
}
.pad-r5 {
    padding-right: 5px;
}
.pad-lr5 {
    padding-left: 5px;
    padding-right: 5px;
}
.pad-t5 {
    padding-top: 5px;
}
.pad-b5 {
    padding-bottom: 5px;
}
.pad-tb5 {
    padding-top: 5px;
    padding-bottom: 5px;
}
.pad-l10 {
    padding-left: 10px;
}
.pad-r10 {
    padding-right: 10px;
}
.pad-lr10 {
    padding-left: 10px;
    padding-right: 10px;
}
.pad-t10 {
    padding-top: 10px;
}
.pad-b10 {
    padding-bottom: 10px;
}
.pad-tb10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
// bootstrap custom
.btn-green {
    @include borderRadius(15px);
}
.btn.btn-success {
    background-color: $green2;
    border-color: $green2;
}
.btn.btn-white {
    background-color: $white;
    @include borderRadius(15px);
    color: $btnGreen;

    &:focus {
        outline: none;
    }
}
.btn.btn-pink {
    background-color: $pink;
    @include borderRadius(12px);
    color: $white;
    border: 0px;
    padding: 10px 30px;

    &:focus {
        outline: none;
    }
    &.disabled:hover {
        color: $pink;
        box-shadow: 0px 5px 10px #ddd inset;
    }
}
#getIntrosignModal {
    .form-control {
        color: $green2;
        @include borderRadius(12px);

        &::-webkit-input-placeholder {
            color: $green2;
        }
    }
}
#loginModal {
    .form-control {
        @include borderRadius(12px);

        &::-webkit-input-placeholder {
            color: $green2;
        }
    }
}
nav {
    a.text-white {
        @extend .text-white;
        outline: none;

        &:focus {
            @extend .text-white;
        }
        &:hover {
            @extend .text-white;
        }
    }
}
.navbar {
    nav {
        a:not(:last-child) {
            padding-left: 10px;
            padding-right: 10px;
            margin: auto 10px;
        }
    }
}