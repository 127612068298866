// * Variables *
$white: #FFF;
$green: #00CE00;
$green2: #74B05D;
$btnGreen: #74B05D;
$pink: #FF3159;
$bodyColor: $white;
$whiteSpaceH: 150px;
$logoDefault320Width: 153px;
$logoDefault320Height: 111px;
$logoFooter: 122px;
$pinkSquareDefault320Width: 260px;
$mobileSmWidth: 320px;
$mobileMdWidth: 480px;
